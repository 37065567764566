@import url(https://fonts.googleapis.com/css?family=Open+Sans|Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.landinghero {
    background: transparent;
    z-index: 1;
}
.headerMain.down {
    background: linear-gradient(rgba(0, 0, 0, 0.66) 22%, rgba(0, 0, 0, 0.29) 66%, rgba(0, 0, 0, 0) 99%) !important;
}

.headerMain.up{
    background: black !important;
    box-shadow: 0px 1px 15px rgb(255, 48, 34) !important;
}
.landinghero label.dv-star-rating-star.dv-star-rating-full-star > span > div,
.landinghero label.dv-star-rating-star.dv-star-rating-empty-star > span > div {
    /* box-shadow: 0 0 black; */
    padding: 3px;
}
.landinghero .dv-star-rating.dv-star-rating-non-editable {
    width: auto;
    display: block;
    /* width: 100%; */
    float: left;
    padding: 0;
}
button#playMovie {
    border: 1px solid #f44336;
}
button#playMovie svg {
    color: #f44336;
}
.heroBg{
    background-size: cover;
    background-attachment: fixed;
}
.mainPageMovie > div:not(.heroBg) {
    background: black;
    z-index: 3;
    position: relative;
}
form#searchMovieInput {
    text-align: center;
    padding: 1em;
}
#searchResultsSection input[name=search] {
    color: white;
    background: #2b2b2b9c !important;
    border: none;
    padding: 1em;
    width: 80%;
    font-size: 1.5em;
    margin: auto;
    text-align: center;
    border-radius: 6px;
    text-transform: uppercase;
    font-weight: bold;
    color: #f44336 !important;
    letter-spacing: 2px;
}
.movieCard {
    min-height: 200px;
    background-size: cover;
    width: 100%;
    display: block;
    margin: auto;
    opacity: .9;
}
.movieCard:hover{
    opacity: 1;
}
.container.mainFilm {
    padding: 1em 5em !important;
}
.landinghero .heading{
    color: white !important;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    top: auto;
    bottom: auto;
    font-size: 3.5em;
    margin-bottom: .1em;
    margin-top: .1em;
}

.landinghero .herobutton svg {
    vertical-align: sub;
}

.landinghero .herobutton:hover svg{
    color: white !important;
}
.landinghero .herobutton:hover{
    text-decoration: none;
    background: #f44336;
    color: white;
    cursor: pointer;
    transition: all 0.5s ease;;
}
.landinghero .herobutton{
    color: white;
    text-align: left;
    float: left;
    background: rgba(119, 119, 119, 0.342);
    display: block;
    padding: .6em 2em;
    border-radius: 10px;
    font-size: 1.2em;
    margin: .3em .5em;
    text-decoration: none;
}
.landinghero .subheader{
    font-family: 'Open Sans', sans-serif;
    color: rgba(256,256,256,.7) !important;
    font-size: 1.1em;
    max-width: 40em;
    text-align: left;
    font-weight: 200;
}
.landinghero div[color="#fff"] div {
    padding: 1vh;
    width: 100%;
    max-width: 1500px;
    overflow: hidden;
    box-sizing: border-box;
}
.maxWidth {
    max-width: 1500px;
    margin: auto;
}
ul.pagination {
    color: white;
    list-style: none;
    padding: 0;
    text-align: center;
    padding: 1em 0em;
}

a:link {
    text-decoration: none !important;
}
ul.pagination li a{
    cursor: pointer;
}
ul.pagination li {
    color: white;
    display: inline;
    /* border: 1px solid white; */
    padding: .5vh 1vh !important;
    /* display: block; */
    /* width: 10px; */
    /* position: inherit; */
}
ul.pagination li.active {
    background: #f44336;
    border-radius: 10px;
}
.movieCard .movieTitle {
    color: white;
    font-weight: bold;
    color: white;
    float: left;
    /* letter-spacing: 2px; */
    text-transform: uppercase;
    text-shadow: 1px 1px 2px black;
    display: block;
    width: 100%;
    margin: 0;
}
.movieDescription p {
    display: block;
    display: -webkit-box;
    max-width: 400px;
    /* height: 45px; */
    
    margin: 0 auto;
    font-size: .7em;
    /* line-height: 1.4; */
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #ffffffa1;
    text-decoration: none;
    text-shadow: 1px 1px 2px black;
    /* display: none; */
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.movieDescription {
    overflow: hidden;
    padding: 1em 2em;
    margin-bottom: 0;
    padding-top: 30%;
}
.movieCard{
    background-position: center;
}
.sectionHeader {
    margin: auto;
    color: white;
    padding: 1em 1em .5em 1em;
    border-bottom: 1px solid rgba(244, 67, 54, 0.59);
    margin-bottom: 1em;
}
.primarytext{
    color: rgb(244, 67, 54);
}
.primarybg{
    background: rgb(244, 67, 54);
}
.detailsholder div[role=button], .landinghero div[color="#fff"] div div[role=button] {
    background: transparent;
    border: 1px #f44336 solid;
    padding: 0em 1em;
    max-width: 80px;
    float: left;
}
.landinghero div[color="#fff"]{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+90,000000+100&0+3,0.92+100 */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 20%,rgb(0,0,0) 90%,rgb(0,0,0) 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eb000000',GradientType=0 ) !important; /* IE6-9 */
}

.padding-reset-x-0{
    display: inline-block;
    padding: 0 !important;
}




/* HEADER */
.headerMain{
    /* background: transparent !important; */
    box-shadow: none !important;
    transition: background 0.5s ease-in-out;
}
body{
    background:black;
}
/* END OF HEADER */

.MuiTypography-body2-75 {
    color: rgba(255, 255, 255, 0.87) !important;
    font-weight: bold;
}
.MuiTypography-colorTextSecondary-100{
    color: rgba(255, 255, 255, 0.54) !important;
}
.MuiIconButton-root-49{
    color: rgb(244, 67, 54) !important;
}
#iFrameFullScreen{
    display: none;
}
#iFrameFullScreen.fullscreen{
    display:block;border: 0; position:fixed; top:0; left:0; right:0; bottom:0; width:100%; height:100%;
    z-index: 9;
}

#closeIframe:hover{
    background: #f44336;
}

#closeIframe.active{
    display: block;
}
#closeIframe a{
    color: rgba(255, 255, 255, 0.466) !important;
}
#closeIframe {
    position: fixed;
    top: 0;
    z-index: 999999;
    font-size: 1em;
    background: #00000069;
    width: 20px;
    height: 20px;
    padding: 5px 5px;
    line-height: 1em;
    text-align: center;
    cursor: pointer;
    display: none;
    opacity: .6;
}

.recommendedItem:hover, .recommendedItem:hover h2 {
    opacity: 1;
}

.recommendedItem {
    min-height: 20em;
    width: 100%;
    display: block;
    background-size: cover;
    opacity: .7;
    cursor: pointer;
    background-position: center;
}

#toHome * {
    padding: 0;
    font-size: 1em;
}
#toHome {
    padding: .6em 1em;
}

.alice-carousel__stage-item * {
    color: white;
}
.detailsholder .textholder{
    position: absolute;
    bottom: 0;
    padding: 2em;
}
.detailsholder div[role=button] span{
    /* color: #f44336 !important; */
}
.detailsholder h2 {
    margin-bottom: .5em;
    opacity: .7;
}
.detailsholder div[role=button]{
    background: transparent;
    border: 1px #f44336 solid;
    padding: 0em 1em;
}
div.dateChip[role=button]{
    background: transparent;
    border: 1px #f44336 solid;
    padding: 0em .2em;
    font-size: .7em !important;
}
.detailsholder {
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 3%,rgb(0,0,0) 90%,rgb(0,0,0) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#eb000000',GradientType=0 ) !important;
}

.alice-carousel__dots, .alice-carousel__prev-btn, .alice-carousel__next-btn{
    display:none !important;
}
.genreHolder{
    margin-bottom: 2em;
}
span.genreItem {
    color: white;
    float: left;
    letter-spacing: 2px;
    text-transform: uppercase;
}
span.genreItem:not(:last-child):after {
    content: "/";
    padding: .4em;
    /* opacity: .4; */
    color: #f44336;
}

.LandingList-cardbgtransparent-233 .herobutton {
    margin: auto;
}
.landinghero .heading small {
    font-size: 0.5em;
    display: block;
    margin-bottom: .5em;
    font-weight: normal;
}

.castHolder {
    color: white;
    padding: 0em 2em 3em 2em;
    text-align: center;
}
.marginAuto{
    margin:auto;
}

.crewItem .name_ {
    color: #f44336;
    display: block;
    position: relative;
    font-size: 1.3em;
}
span.crewItem {
    /* max-width: 30vw; */
    display: inline-table;
    margin: .5em 1em;
    text-align: center;
}

.videoHolder{
    text-align: center;
}
.videoHolder iframe {
    max-width: 100%;
    width: 100%;
    min-height: 480px;
    max-width: 800px;
    margin: auto;
}
.videoHolder > div {
    display: contents;
}
.Player__videoContainer___2TVqS {
    zoom:1.3 !important;
    top: -15vh !important;
    left: -12vw !important;
    opacity: 0;
    position: fixed;
    z-index: 0;
}
.sectionHeader svg {
    vertical-align: bottom !important;
}
img.posterImg {
    box-shadow: 1px 1px 25px -3px black;
    width: 100%;
}
@media (max-width: 600px) {
    .mainFilm{
        padding-top: 10vh !important;
    }
    .landinghero .heading{
        font-size: 2em;
    }  
    .landinghero .subheader {
        /* max-height: 10vh; */
        overflow: hidden;
        text-overflow: ellipsis;
        /* display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical; */
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.6;
        font-size: .9em;
    }
    .videoHolder iframe {
        min-height: 290px;
    }
    span.genreItem{
        font-size: .8em;
    }
    #toHome{
        padding: .5em 0em;
    }
    #playMovie{
        padding: .3em 1em;
        font-size: .9em;
        margin-top: 1.2em;
    }
    #toHome{
        padding: .1em .1em;
        font-size: .9em;
        margin-top: 1.2em;
    }
    .Player__videoContainer___2TVqS {
        zoom: 1;
        left: -60vw !important;
    }
    .container.mainFilm {
        padding: 5em 1em 0em 1em !important
    }
    img.posterImg {
        display: none;
    }
    .landinghero label.dv-star-rating-star.dv-star-rating-full-star > span > div > svg, .landinghero label.dv-star-rating-star.dv-star-rating-empty-star > span > div > svg {
        height: 20px !important;
        width: 20px !important;
    }
    .landinghero label.dv-star-rating-star.dv-star-rating-full-star > span > div, .landinghero label.dv-star-rating-star.dv-star-rating-empty-star > span > div{
        padding: 1px;
    }
}



#root.landscape img.posterImg {
    box-shadow: 1px 1px 25px -3px black;
    width: 80%;
}
#root.landscape .landinghero .heading {
    font-size: 1.5em;
}
#root.landscape button#playMovie svg {
    vertical-align: middle;
}
#root.landscape #toHome {
    padding: 0.1em 0.1em;
    line-height: initial;
    height: initial;
}
#root.landscape .mainFilm {
    padding-top: 0em !important;
    top: 0;
}
#root.landscape button#playMovie {
    padding: 0.4em 1em .4em 1em;
    font-size: 1em;
}
#root.landscape .dv-star-rating-star svg {
    height: 20px;
    width: 20px;
}

#root.landscape .detailsholder div[role=button],#root.landscape .landinghero div[color="#fff"] div div[role=button]{
    line-height: 1px !important;
    height: 20px !important;
}
#root.landscape .genreHolder {
    margin: 0 !important;
}
#root.landscape .landinghero .subheader {
    margin-top: 1px;
    font-size: 0.8em;
    max-width: unset;
    max-height: 75px;
    overflow: hidden;
    margin: .2em 0em;
}
center.notavailable {
    color: #ffffff38;
    font-size: 1.5em;
}
